//@ts-nocheck
import React from 'react';
import { graphql, Link } from 'gatsby';
// Import types
import { SanityColumnImageBlocksProps } from '../../types/page-builder-types';
// Import ui components
import {
  ColumnImageBlocks,
  Badge,
  Banner,
  Carousel,
  Box,
  InfoCard,
  Colors,
  getResponsiveProp,
  TextAlign,
  TAspectRatio
} from '@parsleyhealth/cilantro-ui';
import styled from '@emotion/styled';
import { ButtonsList } from './ui';
import BlockContent from '../BlockContent';
import { useDarkColor } from '../../hooks/use-dark-color';
import useTheme from '../../hooks/use-theme';
import useDeviceDetect from '../../hooks/use-is-device-detect';
import SanityImage from '../NewSanityImage';
import { SanityButtonLink } from 'graphql-types';

const StyledLink = styled(Link)`
  color: inherit;
`;

// Export component
export const ColumnImageBlocksComponent = (
  props: SanityColumnImageBlocksProps
) => {
  const { isMobile } = useDeviceDetect();
  const numberOfColumns = props.columnLayout ? Number(props.columnLayout) : 3;
  const numberOfItems = props.items.length;
  const carouselEnable = numberOfItems > 3 || isMobile;
  const { backgroundColorHex } = useTheme(props.theme);
  const result = useDarkColor(backgroundColorHex);
  const headingEnabled = props.heading ? true : false;
  const imageSizes = getResponsiveProp(
    '100vw',
    `${100 / (numberOfItems > 4 ? 4 : numberOfItems)}vw`
  );

  return (
    <ColumnImageBlocks
      keepTextSmall={props.keepTextSmall}
      useGradient={props.useGradient}
      carousel={carouselEnable}
      items={props.items.map(item => ({
        title: item.title,
        description: item.description,
        showCTA: item.showCTA,
        showTitle: item.showTitle,
        showDescription: item.showDescription,
        sort: item.sort ? Number(item.sort) : 1,
        image: (
          <SanityImage
            image={item?.image}
            imageSizes={imageSizes}
            aspectRatio={(item?.aspectRatio as TAspectRatio) ?? undefined}
          />
        ),
        buttons: <ButtonsList align="left" buttons={item.buttons} />,
        badge:
          item.badge && item.badge.showBadge ? (
            <Badge label={item.badge.label} theme={item?.badge?.theme} />
          ) : (
            undefined
          )
      }))}
      backgroundColor={backgroundColorHex}
      textColor={props?.textColor?.hex as keyof Colors}
      numberOfColumns={numberOfColumns}
      textAlign={props.alignment as TextAlign}
      justifyContent={props.alignment}
      heading={props.heading}
      banner={
        props.banner && props.banner.showBanner ? (
          <Banner
            theme={props.banner.theme}
            content={<BlockContent blocks={props.banner._rawDescription} />}
          />
        ) : (
          undefined
        )
      }
    >
      {carouselEnable ? (
        <Box m="auto" px={getResponsiveProp('none', 'm')}>
          {
            <Carousel
              useDarkMode={result}
              arrowsHeight={0}
              arrowsDistance={260}
              numberOfItems={4}
              infinite
            >
              {props.items.map((item, i) => {
                const button = item.buttons?.length
                  ? (item.buttons[0] as SanityButtonLink)
                  : null;
                const destination = button
                  ? button.destination.replace(
                      'https://www.parsleyhealth.com',
                      ''
                    )
                  : null;
                return (
                  <Box width="95%" key={`info-card-${i}`}>
                    <InfoCard
                      fixedImageHeight={300}
                      heading={headingEnabled}
                      title={
                        button ? (
                          <StyledLink to={destination}>{item.title}</StyledLink>
                        ) : (
                          item.title
                        )
                      }
                      description={
                        button ? (
                          <StyledLink to={destination}>
                            {item.description}
                          </StyledLink>
                        ) : (
                          item.description
                        )
                      }
                      buttons={
                        <ButtonsList align="left" buttons={item.buttons} />
                      }
                      image={
                        button ? (
                          <StyledLink to={destination}>
                            <SanityImage
                              image={item?.image}
                              imageSizes={imageSizes}
                              coverContainer
                            />
                          </StyledLink>
                        ) : (
                          <SanityImage
                            image={item?.image}
                            imageSizes={imageSizes}
                            coverContainer
                          />
                        )
                      }
                      showTitle={item.showTitle}
                      showDescription={item.showDescription}
                      showCTA={item.showCTA}
                      badge={
                        item.badge && item.badge.showBadge ? (
                          <Badge
                            label={item.badge.label}
                            theme={item.badge.theme}
                          />
                        ) : (
                          undefined
                        )
                      }
                      justifyContent={props.alignment}
                      textAlign={props.alignment as TextAlign}
                      textColor={props?.textColor?.hex as keyof Colors}
                    />
                  </Box>
                );
              })}
            </Carousel>
          }
        </Box>
      ) : null}
    </ColumnImageBlocks>
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityColumnImageBlocksFragment on SanityColumnImageBlocks {
    _key
    theme
    items {
      _key
      aspectRatio
      title
      description
      showCTA
      showTitle
      showDescription
      sort
      image {
        ...SanityImageFragment
      }
      buttons {
        ...SanityButtonsFragment
      }
      badge {
        label
        theme
        showBadge
      }
    }
    alignment
    elementId
    columnLayout
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    banner {
      showBanner
      theme
      _rawDescription
    }
    heading
    keepTextSmall
    useGradient
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
