import React from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanityInfographicsProps } from '../../types/page-builder-types';

// Import ui components
import { Infographics } from '@parsleyhealth/cilantro-ui';
import { SingleButton } from './ui';

// Export component
export const InfographicsComponent = (props: SanityInfographicsProps) => {
  const numberOfColumns = props.columnLayout ? Number(props.columnLayout) : 3;
  return (
    <Infographics
      button={<SingleButton {...props.buttonCta} />}
      items={props.items.map(item => ({
        description: item.description,
        data: item.data
      }))}
      showCTA={props.showCTA}
      showTitle={props.showTitle}
      title={props.title}
      numberOfColumns={numberOfColumns}
      backgroundColor={props.backgroundColor?.hex}
      textColor={props.textColor?.hex}
      alignment={props.alignment}
      finePrint={props.finePrint}
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityInfographicsFragment on SanityInfographics {
    _key
    buttonCta {
      _type
      destination
      text
      trackingName
      variant
    }
    items {
      _key
      description
      data
    }
    backgroundColor {
      hex
    }
    textColor {
      hex
    }
    alignment
    showCTA
    showTitle
    elementId
    title
    columnLayout
    finePrint
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
