import React from 'react';
import { useScript } from '../../lib/useScript';

export interface GreenhouseJobBoardProps {
  for: string;
}

export const GreenhouseJobBoard: React.FC<GreenhouseJobBoardProps> = props => {
  const [loaded] = useScript(
    `https://boards.greenhouse.io/embed/job_board/js?for=${props.for}`
  );

  if (loaded) {
    window.Grnhse.Iframe.load();
  }

  return <div id="grnhse_app"></div>;
};
