import React from 'react';
import {
  H2,
  WebsiteWrapper,
  Text,
  UnderlinedText
} from '@parsleyhealth/cilantro-ui';
import styled from '@emotion/styled';
import { GreenhouseJobBoard } from '../../ThirdParty/GreenhouseJobBoard';

const URLComplaint = 'https://lnkd.in/gqEF_uE';

interface GreenhouseProps {}

const Column = styled.div`
  max-width: 600px;
  margin: 0px auto;
`;

export const Greenhouse: React.FC<GreenhouseProps> = () => {
  return (
    <WebsiteWrapper>
      <Column>
        <H2 textAlign="center">Current Openings</H2>
        <Text>
          <GreenhouseJobBoard for="parsleyhealth" />
        </Text>
        <Text variant="pSmall">
          <span>Important Notice:</span> The only official email address domain
          used by Parsley Health is “@parsleyhealth.com”. We are currently
          recruiting for the positions listed on this page. If you have been
          contacted by any other email domain (example:
          “ParsleyHealthTeam@gmail.com”, “recruiting@parsleyhealth-hr.com”),
          texted, or offered a position that is not currently listed on our
          Careers page, do not engage and report to{' '}
          <a href={URLComplaint}>
            <UnderlinedText variant="pSmall">{URLComplaint}</UnderlinedText>
          </a>
        </Text>
      </Column>
    </WebsiteWrapper>
  );
};

export default Greenhouse;
