import React from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from '@emotion/styled';
import useTheme from '../../hooks/use-theme';

// Import types
import { SanityTestimonialProps } from '../../types/page-builder-types';
import { ArrowProps, DotProps } from 'react-multi-carousel/lib/types';
//Import processors
import BlockContent from '../BlockContent';
import { useDarkColor } from '../../hooks/use-dark-color';

// Import ui components
import {
  Testimonials,
  TestimonialCard,
  ArrowButton,
  DotButton,
  ComponentThemes,
  TComponentThemeKey,
  Box
} from '@parsleyhealth/cilantro-ui';
import { ButtonsList } from './ui';

// Carousel types
interface CustomArrow extends ArrowProps {
  next?: boolean;
  result?: boolean;
}

interface CustomDotsProps extends DotProps {
  result?: boolean;
}

const carouselConstants = {
  Desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 1,
    partialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: {
      max: 500,
      min: 0
    },
    items: 1,
    partialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 500
    },
    items: 1,
    partialVisibilityGutter: 0
  }
};

// Carousel Navigation Custom Controls
const CustomLeftArrow = ({ onClick, next, result }: CustomArrow) => {
  return (
    <div onClick={onClick}>
      <ArrowButton darkStyle={result} next={next} />
    </div>
  );
};

const CustomRightArrow = ({ onClick, next, result }: CustomArrow) => {
  return (
    <div onClick={onClick}>
      <ArrowButton next={next} darkStyle={result}></ArrowButton>
    </div>
  );
};

const CustomDots = ({ onClick, active, result }: CustomDotsProps) => {
  return (
    <li onClick={onClick}>
      <DotButton active={active} darkStyle={result} />{' '}
    </li>
  );
};

const CarouselContainer = styled(Box)`
  .react-multi-carousel-dot-list {
    bottom: 20px;
  }
`;

// Export component
export const TestimonialComponent = (props: SanityTestimonialProps) => {
  const { testimonials, lookAndFeel, showStars, autoSlide, hideArrows } = props;
  const themeField = props.theme ? props.theme : 'whiteBackgroundDarkText';
  const { backgroundColor } = ComponentThemes[themeField as TComponentThemeKey];
  const result = useDarkColor(backgroundColor);
  const isShortReview = lookAndFeel === 'short-review';
  const { bodyColorHex } = useTheme(props.theme);
  const [ref, inView] = useInView();

  return (
    <div ref={ref}>
      <Testimonials
        heading={props.heading}
        cta={
          props.buttons ? <ButtonsList buttons={props.buttons} /> : undefined
        }
        includePaddingY={!isShortReview}
      >
        <CarouselContainer pt={isShortReview ? 'l' : 'none'}>
          <Carousel
            autoPlay={inView && autoSlide}
            autoPlaySpeed={4000}
            arrows={!hideArrows}
            customLeftArrow={<CustomLeftArrow result={result} />}
            customRightArrow={<CustomRightArrow next result={result} />}
            showDots
            customDot={<CustomDots result={result} />}
            slidesToSlide={1}
            infinite
            draggable
            renderDotsOutside={false}
            responsive={carouselConstants}
          >
            {testimonials.map(testimonial => (
              <TestimonialCard
                starColor={bodyColorHex}
                lookAndFeel={lookAndFeel}
                showRating={showStars}
                rating={testimonial.rating ? testimonial.rating : 0}
                client={testimonial.client}
                description={testimonial.description}
                testimonial={
                  <BlockContent
                    blocks={testimonial._rawTestimonial}
                    pStyle={isShortReview ? 'pSerifl' : 'pSerifXL'}
                  />
                }
                key={testimonial.client}
              />
            ))}
          </Carousel>
          {props.buttons ? <ButtonsList buttons={props.buttons} /> : null}
        </CarouselContainer>
      </Testimonials>
    </div>
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityTestimonialFragment on SanityTestimonialsComponent {
    _key
    buttons {
      ...SanityButtonsFragment
    }
    elementId
    heading
    showStars
    hideArrows
    autoSlide
    lookAndFeel
    testimonials {
      _key
      description
      client
      _rawTestimonial
      rating
    }
    theme
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
