import React from 'react';
import { graphql } from 'gatsby';
import TextBlockSectionWrapper from '../text-block-section-wrapper';

// Import types
import { SanityTextBlockSectionProps } from '../../types/page-builder-types';

// Export component
export const TextBlockSectionComponent = (
  props: SanityTextBlockSectionProps
) => <TextBlockSectionWrapper {...props} />;

// Create a fragment for the component
export const query = graphql`
  fragment SanityTextBlockSectionFragment on SanityTextBlockSection {
    _key
    _rawDescription
    _type
    alignment
    buttons {
      ...SanityButtonsFragment
    }
    image {
      _type
      ...SanityImageFragment
    }
    showDescription
    showTitle
    theme
    elementId
    title
    useBackgroundImageOnMobile
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
