import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

// Import types
import { SanityVideoCarouselProps } from '../../types/page-builder-types';

// Import ui components
import {
  VideoCarousel,
  Box,
  Carousel,
  InfoCard,
  VideoPlayer
} from '@parsleyhealth/cilantro-ui';
import useVideoTracking from '../../hooks/use-video-tracking';
import useTheme from '../../hooks/use-theme';
import BlockContent from '../BlockContent';

const CardContainer = styled(Box)`
  width: 100%;
`;

// Export component
export const VideoCarouselComponent = (props: SanityVideoCarouselProps) => {
  const { onPlay, onPause, onEnd } = useVideoTracking();
  const { bodyColor } = useTheme(props.theme);

  return (
    <VideoCarousel heading={props.heading}>
      <Box>
        <Carousel>
          {props?.videos?.map((card: any) => (
            <CardContainer mx="auto" px="s">
              <InfoCard
                textColor={bodyColor}
                title={card.title}
                description={<BlockContent blocks={card.description} />}
                showDescription
                showTitle
                textAlign="left"
                justifyContent="left"
                heading
                image={
                  <Box mb="s">
                    <VideoPlayer
                      onPause={() => onPause()}
                      onPlay={() => onPlay()}
                      ended={() => onEnd()}
                      assetDocument={card.video.asset}
                      autoload
                      showControls
                    />
                  </Box>
                }
              />
            </CardContainer>
          )) ?? []}
        </Carousel>
      </Box>
    </VideoCarousel>
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityVideoCarouselFragment on SanityVideoCarousel {
    _key
    description: _rawDescription
    elementId
    heading
    theme
    videos {
      description: _rawDescription
      title
      video {
        asset {
          _type
          assetId
          filename
          playbackId
          status
          thumbTime
          _key
        }
      }
    }
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
