import React from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanityTextAndVideoTwoColumnProps } from '../../types/page-builder-types';

// Import ui components
import {
  TextAndVideoTwoColumn,
  TComponentThemeKey,
  ComponentThemes,
  getResponsiveProp
} from '@parsleyhealth/cilantro-ui';
import { Underline } from './procesors';
import { processLayout } from './procesors';
import { ButtonsList } from './ui';
import useVideoTracking from '../../hooks/use-video-tracking';
import BlockContent from '../BlockContent';
import { useDarkColor } from '../../hooks/use-dark-color';
import SanityImage from '../NewSanityImage';

// Export component
export const TextAndVideoTwoColumnComponent = (
  props: SanityTextAndVideoTwoColumnProps
) => {
  const themeField = props.theme ? props.theme : 'whiteBackgroundDarkText';
  const { backgroundColor } = ComponentThemes[themeField as TComponentThemeKey];
  const useSeparator = !useDarkColor(backgroundColor);
  const { onPlay, onPause, onEnd } = useVideoTracking();
  return (
    <>
      <TextAndVideoTwoColumn
        buttons={<ButtonsList buttons={props.buttons} />}
        image={
          <SanityImage
            image={props.image}
            imageSizes={getResponsiveProp('100vw', '50vw')}
          />
        }
        text={<BlockContent blocks={props._rawText} />}
        title={props.title}
        videoSrc={props.embeddedVideo}
        useEmbeddedVideo={props.useEmbeddedVideo}
        videoAsset={props.video.asset}
        separator={!props.hideSeparator && useSeparator}
        useReducedBottomPadding={props.useReducedBottomPadding}
        useReducedTopPadding={props.useReducedTopPadding}
        eyebrow={props.stepEyebrow}
        layout={processLayout(props.layout)}
        playVideoCta={props.playVideoCta}
        underline={
          <Underline
            theme={props.theme as TComponentThemeKey}
            underline={props.underline}
          />
        }
        handlePlay={() => onPlay()}
        handlePause={() => onPause()}
        handleEnded={() => onEnd()}
      />
    </>
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityTextAndVideoTwoColumnFragment on SanityTextAndVideoTwoColumn {
    _key
    _rawText
    buttons {
      ...SanityButtonsFragment
    }
    elementId
    hideSeparator
    image {
      _type
      ...SanityImageFragment
    }
    layout
    playVideoCta
    stepEyebrow
    theme
    title
    underline
    useReducedBottomPadding
    useReducedTopPadding
    embeddedVideo
    useEmbeddedVideo
    video {
      asset {
        _key
        _type
        assetId
        filename
        playbackId
        status
        thumbTime
      }
    }
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
