import React from 'react';
import { graphql } from 'gatsby';

// Import type
import { SanityTextAndImageProps } from '../../types/page-builder-types';

// Import ui components
import { ButtonsList } from './ui';
import { Underline } from './procesors';
import {
  TextAndImage,
  TComponentThemeKey,
  ComponentThemes,
  getResponsiveProp
} from '@parsleyhealth/cilantro-ui';
import BlockContent from '../BlockContent';
import { useDarkColor } from '../../hooks/use-dark-color';
import SanityImage from '../NewSanityImage';

function processLayout(layout: string): '000' | '001' | '110' | '111' {
  if (
    layout === '000' ||
    layout === '001' ||
    layout === '110' ||
    layout === '111'
  ) {
    return layout;
  }
  throw Error('Layout is incorrect');
}

// Export component
export const TextAndImageComponent = (props: SanityTextAndImageProps) => {
  const themeField = props.theme ? props.theme : 'whiteBackgroundDarkText';
  const { backgroundColor } = ComponentThemes[themeField as TComponentThemeKey];
  const useSeparator = !useDarkColor(backgroundColor);
  return (
    <TextAndImage
      buttons={<ButtonsList buttons={props.buttons} />}
      image={
        <SanityImage
          image={props.image}
          imageSizes={getResponsiveProp('100vw', '50vw')}
          aspectRatio="3:2.5"
        />
      }
      layout={processLayout(props.layout)}
      text={<BlockContent blocks={props._rawText} />}
      title={props.title}
      separator={!props.hideSeparator && useSeparator}
      useReducedBottomPadding={props.useReducedBottomPadding}
      useReducedTopPadding={props.useReducedTopPadding}
      eyebrow={props.stepEyebrow}
      underline={
        <Underline
          theme={props.theme as TComponentThemeKey}
          underline={props.underline}
        />
      }
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityTextAndImageFragment on SanityTextAndImage {
    _key
    _rawText
    buttons {
      ...SanityButtonsFragment
    }
    image {
      _type
      ...SanityImageFragment
    }
    elementId
    layout
    theme
    title
    underline
    useReducedBottomPadding
    useReducedTopPadding
    hideSeparator
    stepEyebrow
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
