import React from 'react';
import BlockContent from '../components/BlockContent';
import { SanityBlock } from '../../graphql-types';

export const toBlockContent = (
  blocks: SanityBlock[],
  pStyle?: string
): React.ReactNode => {
  if (blocks && blocks.length) {
    return <BlockContent blocks={blocks} pStyle={pStyle} />;
  } else {
    return null;
  }
};
