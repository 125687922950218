import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '../../components/BlockContent';
import { ButtonsList } from './ui';
import { Underline } from './procesors';

// Import types
import { SanityTextAndCtaProps } from '../../types/page-builder-types';

// Import ui components
import { TextAndCta, TComponentThemeKey } from '@parsleyhealth/cilantro-ui';

// Export component
export const TextAndCtaComponent = (props: SanityTextAndCtaProps) => {
  const descriptionPStyle = props.pSize || 'pl';
  return (
    <TextAndCta
      headingText={<BlockContent blocks={props._rawHeadingText} pStyle="h2" />}
      subText={
        <BlockContent blocks={props._rawSubText} pStyle={descriptionPStyle} />
      }
      buttons={<ButtonsList buttons={props.cta} />}
      useReducedBottomPadding={props.useReducedBottomPadding}
      useReducedTopPadding={props.useReducedTopPadding}
      underline={
        <Underline
          theme={props.theme as TComponentThemeKey}
          underline={props.underline}
          noMargin
          color="borders"
        />
      }
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityTextAndCtaFragment on SanityTextAndCta {
    _key
    _rawHeadingText
    _rawSubText
    cta {
      ...SanityButtonsFragment
    }
    theme
    elementId
    pSize
    underline
    useReducedBottomPadding
    useReducedTopPadding
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
