import React from 'react';
import { graphql, navigate } from 'gatsby';
import { ButtonsList } from './ui';

// Import types
import { SanityPlanSnapshotsArrayProps } from '../../types/page-builder-types';

// Import ui components
import { PlanSnapshotsArray } from '@parsleyhealth/cilantro-ui';
import { toBlockContent } from '../../lib/to-block-content';

// Export component
export const PlanSnapshotsArrayComponent = (
  props: SanityPlanSnapshotsArrayProps
) => {
  const onClick = (linkPath: string) => {
    navigate(linkPath);
  };
  return (
    <PlanSnapshotsArray
      heading={props.heading}
      planSnapshots={props.planSnapshots.map(item => ({
        planTitle: item.planTitle,
        heading: item.heading,
        description: toBlockContent(item._rawDescription),
        onClick: (linkPath: string) => onClick(linkPath),
        linkPath: item.linkPath,
        properties: item.properties
      }))}
      buttons={<ButtonsList buttons={props.buttons} />}
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityPlanSnapshotsArrayFragment on SanityPlanSnapshotsArray {
    _key
    theme
    elementId
    heading
    buttons {
      ...SanityButtonsFragment
    }
    planSnapshots {
      planTitle
      heading
      _rawDescription
      properties
      linkPath
    }
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
