import { useState, useEffect } from 'react';

const cachedScripts: string[] = [];

export const useScript = (
  src: string,
  isModal = false,
  async = true,
  delay = 0
) => {
  const [state, setState] = useState({
    loaded: false,
    error: false
  });

  useEffect(() => {
    if (cachedScripts.includes(src) && !isModal) {
      setState({
        loaded: true,
        error: false
      });
      return () => undefined;
    } else {
      cachedScripts.push(src);

      const script = document.createElement('script');
      script.async = async;

      if (delay > 0) {
        setTimeout(() => {
          script.src = src;
        }, delay);
      } else {
        script.src = src;
      }

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src]);

  return [state.loaded, state.error];
};
