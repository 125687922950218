import React from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanityCenteredTextProps } from '../../types/page-builder-types';

// Import ui components
import { SectionCenteredTextBlock } from '@parsleyhealth/cilantro-ui';
import BlockContent from '../BlockContent';
import { ButtonsList } from './ui';

// Export component
export const CenteredTextComponent = (props: SanityCenteredTextProps) => {
  const headingStyle = props.headingStyle ? Number(props.headingStyle) : 1;
  const textStyle = props.textStyle ? Number(props.textStyle) : 1;
  return (
    <SectionCenteredTextBlock
      buttons={<ButtonsList align="center" buttons={props.buttons} />}
      text={<BlockContent blocks={props._rawText} />}
      title={props.title}
      headingStyle={headingStyle}
      textStyle={textStyle}
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityCenteredTextFragment on SanityCenteredText {
    _key
    _rawText
    buttons {
      ...SanityButtonsFragment
    }
    theme
    elementId
    title
    headingStyle
    textStyle
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
