import React from 'react';
import { graphql } from 'gatsby';
import DummyComponent from './ui/dummy-component';
import Greenhouse from './ui/greenhouse';
import WorkLives from './ui/work-lives';
import { useCurrentPath } from '../../hooks/use-current-path';
import { SanityNonContentBlockProps } from '../../types/page-builder-types';

const getComponent = (blockType: string): any => {
  switch (blockType) {
    case 'ourWorkLives':
      return WorkLives;
    case 'greenhouse':
      return Greenhouse;
    default:
      console.warn(
        `Page builder doesn't know how to render a block of type ${blockType}`
      );
      // TODO dummy component should only be used in development
      return <DummyComponent blockType={blockType} />;
  }
};

export const NoContentBlock = ({ blockType }: SanityNonContentBlockProps) => {
  const path = useCurrentPath();
  const Component = getComponent(blockType);
  return <Component path={path} />;
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityNoContentBlockFragment on SanityNonContentBlock {
    _key
    blockType
    elementId
    theme
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
