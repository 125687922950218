import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanityRenewalProps } from '../../types/page-builder-types';

import { RenewalModule } from '@parsleyhealth/cilantro-ui';
import { ButtonsList } from './ui';
import BlockContent from '../BlockContent';

export function RenewalComponent(props: SanityRenewalProps): ReactElement {
  const { heading, renewalCards } = props;

  return (
    <RenewalModule
      heading={heading}
      renewalCards={renewalCards.map(
        ({ benefitList, title, borderTopColor, cta, description }) => ({
          benefits: benefitList.map(benefit => benefit.title),
          borderTopColor: borderTopColor.hex,
          title,
          cta: <ButtonsList buttons={cta} />,
          description: <BlockContent blocks={description} />
        })
      )}
    />
  );
}

// Create a fragment for the component
export const query = graphql`
  fragment SanityRenewalCardFragment on SanityRenewalCard {
    title
    benefitList {
      title
    }
    borderTopColor {
      hex
    }
    cta {
      ...SanityButtonsFragment
    }
    description {
      _type
      children {
        _type
        text
      }
    }
  }
  fragment SanityRenewalFragment on SanityRenewal {
    _key
    theme
    elementId
    heading
    renewalCards {
      ...SanityRenewalCardFragment
    }
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
