import React from 'react';
import styled from '@emotion/styled';
import { H2, Text, Box, WebsiteWrapper } from '@parsleyhealth/cilantro-ui';
import { default as DollarIcon } from '../../../assets/icon-dollar.svg';
import { default as ClockIcon } from '../../../assets/icon-clock.svg';
import { default as CrossIcon } from '../../../assets/icon-cross-outline.svg';
import { default as SearchIcon } from '../../../assets/icon-search.svg';
import { default as BikeIcon } from '../../../assets/icon-bike.svg';

interface WorkLivesProps {
  path: string;
}

const Section = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const BenefitBox = styled(Box)`
  text-align: center;
  padding: 0 2rem 4rem;
  flex: 0 0 30%;
  width: 30%;

  @media (min-width: 320px) and (max-width: 500px) {
    flex: 0 0 48%;
    width: 48%;
    svg {
      height: 50px;
      width: 50px;
    }
  }
`;

export const WorkLives: React.FC<WorkLivesProps> = () => {
  return (
    <WebsiteWrapper>
      <Box mx="auto" maxWidth="700px" py={'xxl'}>
        <H2 mb="l" textAlign="center">
          Our Work lives
        </H2>
        <Section mb="xl">
          <BenefitBox>
            <DollarIcon />
            <Text>
              Competitive <br />
              salary
            </Text>
          </BenefitBox>
          <BenefitBox>
            <ClockIcon />
            <Text>
              Flexible <br />
              hours
            </Text>
          </BenefitBox>
          <BenefitBox>
            <CrossIcon />
            <Text>
              Benefits <br />
              and perks
            </Text>
          </BenefitBox>
          <BenefitBox>
            <SearchIcon />
            <Text>
              Mission <br />
              driven team
            </Text>
          </BenefitBox>
          <BenefitBox>
            <BikeIcon />
            <Text>
              Commuting <br />
              benefits
            </Text>
          </BenefitBox>
        </Section>
      </Box>
    </WebsiteWrapper>
  );
};

export default WorkLives;
