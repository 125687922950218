import React from 'react';

// Import ui components
import { TextBlockSection } from '@parsleyhealth/cilantro-ui';
import SanityImage from './NewSanityImage';
import BlockContent from './BlockContent';
import { ButtonsList } from './page-builder/ui';
import { SanityTextBlockSection } from '../../graphql-types';

export interface TextBlockSectionWrapperProps extends SanityTextBlockSection {
  className?: string;
}

const TextBlockSectionWrapper = (props: TextBlockSectionWrapperProps) => {
  const imageClassName = props?.className ? `${props?.className}-image` : '';
  return (
    <TextBlockSection
      alignment={props.alignment}
      backgroundImage={
        <SanityImage
          image={props.image}
          className={imageClassName}
          coverContainer
        />
      }
      buttons={<ButtonsList buttons={props.buttons} />}
      className={props?.className}
      description={
        props._rawDescription ? (
          <BlockContent blocks={props._rawDescription} />
        ) : (
          undefined
        )
      }
      showDescription={props.showDescription !== false}
      showTitle={props.showTitle !== false}
      title={props.title}
      useBackgroundImageOnMobile={props.useBackgroundImageOnMobile}
    />
  );
};

export default TextBlockSectionWrapper;
