import React from 'react';
import { Box } from '@parsleyhealth/cilantro-ui';

interface ErroredModuleProps {
  children: React.ReactNode;
}

export const ErroredModule = ({ children }: ErroredModuleProps) => (
  <Box
    display="flex"
    justifyContent="center"
    py="l"
    style={{
      alignItems: 'center',
      background: 'red',
      color: 'white',
      minHeight: '100px',
      lineHeight: '1.5'
    }}
  >
    <pre>
      <strong>ERROR:</strong> {children}
    </pre>
  </Box>
);
