import track from '@parsleyhealth/cilantro-track';

export const useVideoTracking = () => {
  let initTime: any, pauseTime: any;

  const onPlay = () => {
    initTime = Date.now();
    track('Video Playing', {});
  };

  const onPause = () => {
    pauseTime = Date.now();
    const difference = Math.abs(pauseTime - initTime);
    const timestamp = Math.floor(difference / 1000); //timestamp on seconds
    track('Video Pause', {
      timestamp: timestamp
    });
  };

  const onEnd = () => {
    track('Video Ended', {});
  };

  return { onPlay, onPause, onEnd };
};

export default useVideoTracking;
