import React from 'react';
import { graphql } from 'gatsby';

// Import type
import { SanityTextImageAndIconsProps } from '../../types/page-builder-types';

// Import ui components
import { ButtonsList } from './ui';
import {
  TextImageAndIcons,
  TComponentThemeKey,
  ComponentThemes,
  getResponsiveProp
} from '@parsleyhealth/cilantro-ui';
import { useDarkColor } from '../../hooks/use-dark-color';
import { processLayout } from './procesors';
import SanityImage from '../NewSanityImage';

// Export component
export const TextImageAndIconsComponent = (
  props: SanityTextImageAndIconsProps
) => {
  const themeField = props.theme ? props.theme : 'whiteBackgroundDarkText';
  const { backgroundColor } = ComponentThemes[themeField as TComponentThemeKey];
  const result = useDarkColor(backgroundColor);

  return (
    <TextImageAndIcons
      buttons={<ButtonsList buttons={props.buttons} />}
      image={
        <SanityImage
          image={props.image}
          imageSizes={getResponsiveProp('100vw', '50vw')}
          aspectRatio="3:2.5"
        />
      }
      layout={processLayout(props.layout)}
      items={props.items.map(item => ({
        title: item.title,
        description: item.description,
        icon: item.icon
      }))}
      title={props.title}
      separator={!result}
      theme={props.theme}
      buttonAlignment={props.buttonAlignment}
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityTextImageAndIconsFragment on SanityTextImageAndIcons {
    _key
    buttons {
      ...SanityButtonsFragment
    }
    image {
      _type
      ...SanityImageFragment
    }
    elementId
    layout
    theme
    title
    items {
      _key
      title
      description
      icon
    }
    separatorColor
    useSeparatorBottom
    useSeparatorTop
    buttonAlignment
  }
`;
