import React from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanitySimpleReviewsComponentProps } from '../../types/page-builder-types';
import BlockContent from '../BlockContent';
import { useDarkColor } from '../../hooks/use-dark-color';

// Import ui components
import {
  SimpleReview,
  Carousel,
  SimpleReviewCard,
  ComponentThemes,
  TComponentThemeKey,
  Palette
} from '@parsleyhealth/cilantro-ui';

//type
type Review = {
  review: React.ReactNode;
  client: string;
  rating: number;
  id: string;
  starColor?: string;
};

// Export component
export const SimpleReviewComponent = (
  props: SanitySimpleReviewsComponentProps
) => {
  const rawReviews = props.testimonials;
  const reviews = rawReviews.length > 9 ? rawReviews.slice(0, 9) : rawReviews;
  const reviewsProccessed = reviews.map((review: any) => ({
    review: <BlockContent blocks={review._rawTestimonial} pStyle="ps" />,
    ...review
  }));
  const themeField = props.theme ? props.theme : 'whiteBackgroundDarkText';
  const { backgroundColor } = ComponentThemes[themeField as TComponentThemeKey];
  const result = useDarkColor(backgroundColor);
  return (
    <SimpleReview heading={props.heading} {...props}>
      <Carousel useDarkMode={result}>
        {reviewsProccessed.map((review: Review) => (
          <SimpleReviewCard
            {...review}
            starColor={Palette['redesign-sage']}
            key={review.id}
          />
        ))}
      </Carousel>
    </SimpleReview>
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanitySimpleReviewFragment on SanitySimpleReviewsComponent {
    _key
    theme
    elementId
    heading
    testimonials {
      description
      client
      rating
      _rawTestimonial
    }
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
