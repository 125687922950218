import React from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanityFaqBlockPageBuilderProps } from '../../types/page-builder-types';

// Import ui components
import Faq from '../Common/Faq';

// Export component
export const FaqBlockPageBuilderComponent = (
  props: SanityFaqBlockPageBuilderProps
) => {
  return <Faq heading={props.heading} questions={props.faq} />;
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityFaqBlockPageBuilderFragment on SanityFaqBlockPageBuilder {
    _key
    elementId
    faq {
      _rawBody
      heading
      id
    }
    heading
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
