import React from 'react';
import Appear from '../animations/appear';
import { SanityFaq } from '../../../graphql-types';
import {
  Accordeon,
  Box,
  H2,
  WebsiteWrapper,
  CollapseButtonX
} from '@parsleyhealth/cilantro-ui';
import track from '@parsleyhealth/cilantro-track';
import BlockContent from '../BlockContent';
import { graphql } from 'gatsby';

interface FaqProps {
  heading: string;
  questions: SanityFaq[];
}

const Faq: React.FC<FaqProps> = ({ heading, questions }) => {
  const handleClick = (description: string) => {
    track('OpenedFAQ', {
      description: description
    });
  };

  return (
    <Appear>
      <WebsiteWrapper includePaddingY>
        <H2 textAlign="center">{heading}</H2>
        <Box
          flexDirection={['column', 'column', 'column', 'row']}
          flexWrap="wrap"
          justifyContent="space-between"
          my="none"
          mx="auto"
          maxWidth="900px"
        >
          {questions.map((question, index) => {
            return (
              <Box
                onClick={() => handleClick(question.heading)}
                key={question.id}
                width={['100%']}
              >
                <Accordeon
                  key={question.id}
                  index={index}
                  title={question.heading}
                  description={<BlockContent blocks={question._rawBody} />}
                  renderCollapseButton={(open: boolean) => (
                    <CollapseButtonX
                      open={open}
                      darkIcon={false}
                      aria-label={open ? 'close item' : 'open item'}
                    />
                  )}
                />
              </Box>
            );
          })}
        </Box>
      </WebsiteWrapper>
    </Appear>
  );
};

export default Faq;

export const query = graphql`
  fragment FaqItem on SanityFaq {
    heading
    id
    _rawBody
    children {
      id
    }
    internal {
      type
    }
  }
`;
