import React from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanityThreeColumnIconProps } from '../../types/page-builder-types';

// Import ui components
import {
  ThreeColumnIcon,
  TComponentThemeKey,
  ComponentThemes
} from '@parsleyhealth/cilantro-ui';
import { SingleButton } from './ui';
import { useDarkColor } from '../../hooks/use-dark-color';

// Export component
export const ThreeColumnIconComponent = (props: SanityThreeColumnIconProps) => {
  const numberOfColumns = props.columnLayout ? Number(props.columnLayout) : 3;
  const themeField = props.theme ? props.theme : 'whiteBackgroundDarkText';
  const { backgroundColor } = ComponentThemes[themeField as TComponentThemeKey];
  const result = useDarkColor(backgroundColor);
  return (
    <ThreeColumnIcon
      button={<SingleButton {...props.buttonCta} />}
      items={props.items.map(item => ({
        description: item.description,
        icon: item.icon,
        link: { destination: item.link?.destination, label: item.link?.label },
        showLink: item.showLink,
        title: item.title
      }))}
      showConnectingLineBetweenIcons={props.showConnectingLineBetweenIcons}
      showCTA={props.showCTA}
      showTitle={props.showTitle}
      title={props.title}
      showDescription={props.showDescription}
      description={props.des}
      numberOfColumns={numberOfColumns}
      separator={!result}
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityThreeColumnIconFragment on SanityThreeColumnIcon {
    _key
    theme
    elementId
    title
    showTitle
    des: description
    showDescription
    columnLayout
    showConnectingLineBetweenIcons
    items {
      _key
      title
      description
      icon
      link {
        destination
        label
      }
      showLink
    }
    buttonCta {
      _type
      destination
      text
      trackingName
      variant
    }
    showCTA
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
