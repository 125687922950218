import React, { FC, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import useLocalStorage from '../../lib/useLocalStorage';
import { SanityBannerPromo } from '../../../graphql-types';
import BlockContent from '../BlockContent';
import track from '@parsleyhealth/cilantro-track';
import './promo-banner.css';

const Banner: FC<SanityBannerPromo> = ({ body, color, enabled, colorHex }) => {
  const [bannerActive, setBannerActive] = useLocalStorage('bannerActive', true);
  const [stateBanner, setStateBanner] = useState(false);
  const isUsingHex = !!colorHex;
  const [containerRef, inView] = useInView({
    triggerOnce: true
  });

  useEffect(() => {
    if (bannerActive) {
      setStateBanner(true);
    }
  }, [bannerActive, stateBanner]);

  useEffect(() => {
    if (bannerActive && stateBanner && inView) {
      track('Saw Banner', {
        description: 'Persistent banner at bottom of page showed'
      });
    }
  }, [stateBanner, bannerActive, inView]);

  if (!(enabled && bannerActive)) {
    return null;
  }

  return (
    <div ref={containerRef}>
      <div
        className="promo-banner-container"
        style={{ backgroundColor: isUsingHex ? colorHex : color?.hex }}
        onClick={() => {
          track('Clicked Banner', {
            description: 'User clicked persistent banner at bottom of page'
          });
        }}
      >
        <div className="promo-banner-content">
          <BlockContent blocks={body} />
          <button
            className="promo-banner-close-button"
            onClick={() => {
              setBannerActive(false);
              setStateBanner(false);
              track('Closed Banner', {
                description: 'User closed persistent banner at bottom of page'
              });
            }}
          >
            <div className="promo-banner-close-button-line" />
            <div className="promo-banner-close-button-line inverted" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
