import {
  ComponentThemes,
  TComponentThemeKey,
  theme as UiTheme
} from '@parsleyhealth/cilantro-ui';

const useTheme = (theme: string) => {
  const themeField = theme ? theme : 'whiteBackgroundDarkText';
  const { backgroundColor, bodyColor, titleColor, svgColor } = ComponentThemes[
    themeField as TComponentThemeKey
  ];
  return {
    backgroundColor,
    bodyColor,
    titleColor,
    svgColor,
    backgroundColorHex: UiTheme.colors[backgroundColor],
    bodyColorHex: UiTheme.colors[bodyColor],
    titleColorHex: UiTheme.colors[titleColor],
    svgColorHex: UiTheme.colors[svgColor]
  };
};

export default useTheme;
