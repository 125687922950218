import React from 'react';
import { Box } from '@parsleyhealth/cilantro-ui';
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
	}
  to {
    opacity: 1;
    transform: translateY(0px)
  }
`;

const Container = styled(({ startAnimation, ...rest }) => <Box {...rest} />)<{
  startAnimation: boolean;
}>`
  animation: ${fadeIn} 0.6s linear;
  animation-play-state: ${props =>
    props.startAnimation ? 'running' : 'paused'};
`;

const Appear = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div ref={ref}>
      <Container startAnimation={inView}>{children}</Container>
    </div>
  );
};

export default Appear;
