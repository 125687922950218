import React from 'react';
import { graphql } from 'gatsby';

// Import types
import { SanityTextAndIconsProps } from '../../types/page-builder-types';

// Import ui components
import { TextAndIcons } from '@parsleyhealth/cilantro-ui';
import { ButtonsList } from './ui';
import BlockContent from '../BlockContent';

// Export component
export const TextAndIconsComponent = (props: SanityTextAndIconsProps) => {
  return (
    <TextAndIcons
      buttons={<ButtonsList buttons={props.buttons} />}
      items={props.items.map(item => ({
        title: item.title,
        description: item.description,
        icon: item.icon
      }))}
      title={props.title}
      description={<BlockContent blocks={props._rawDescription} pStyle="pl" />}
      theme={props.theme}
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityTextAndIconsFragment on SanityTextAndIcons {
    _key
    theme
    elementId
    title
    _rawDescription
    buttons {
      ...SanityButtonsFragment
    }
    items {
      _key
      title
      description
      icon
    }
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
