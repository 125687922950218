import React from 'react';
import { graphql } from 'gatsby';
import useTheme from '../../hooks/use-theme';

// Import types from graphql-types
import { SanityHeroProps } from '../../types/page-builder-types';

// Import ui components
import { Hero, getResponsiveProp, Text } from '@parsleyhealth/cilantro-ui';
import SanityImage from '../NewSanityImage';
import { ButtonsList } from './ui';

import { useCurrentPath } from '../../hooks/use-current-path';

// Export component
export const HeroComponent = (props: SanityHeroProps) => {
  const { titleColor: textColor } = useTheme(props.theme);
  const path = useCurrentPath();
  let imageWithAlt;
  if (props?.heroImage?.desktopImage && props?.heroImage?.alt) {
    imageWithAlt = {
      ...props.heroImage.desktopImage,
      alt: props.heroImage.alt
    };
  }

  // Redesign
  const description = (
    <>
      {props._rawDescription?.map((i: { children: { text: any }[] }) => {
        const text = i.children[0].text;
        return (
          <Text variant="p2a" color="redesign-dark-green">
            {text}
          </Text>
        );
      })}
    </>
  );
  const buttonsData = props?.buttons?.map(button => {
    if (
      path === '/providers/' &&
      button?.trackingName?.toLowerCase() === 'see our doctors'
    ) {
      return {
        ...button,
        variant: 'bordered'
      };
    }
    return button;
  });
  return (
    <Hero
      boxedText={props.boxedText}
      buttons={
        <ButtonsList
          align={props.centered ? 'center' : 'left'}
          buttons={buttonsData}
        />
      }
      centered={props.centered}
      description={description}
      heading={props.heading}
      image={
        imageWithAlt ? (
          <SanityImage
            image={imageWithAlt}
            imageSizes={props.split ? getResponsiveProp('100vw', '70vw') : null}
            eagerLoad
            coverContainer
          />
        ) : null
      }
      split={props.split}
      textColor={textColor}
    />
  );
};

// Create a fragment for the component
export const query = graphql`
  fragment SanityHeroNewFragment on SanityHeroNew {
    theme
    _key
    heroImage {
      desktopImage {
        crop {
          bottom
          left
          right
          top
        }
        hotspot {
          height
          width
          x
          y
        }
        asset {
          _id
          url
          fluid {
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
          metadata {
            lqip
          }
        }
      }
      alt
    }
    heading
    buttons {
      ...SanityButtonsFragment
    }
    boxedText
    centered
    split
    _rawDescription
    description: _rawDescription
    elementId
    separatorColor
    useSeparatorBottom
    useSeparatorTop
  }
`;
